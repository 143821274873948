import "./App.css";

function App() {
  const isMobileDevice = () => {
    return window.innerWidth <= 768; // Adjust the threshold as needed
  };

  const openTelegram = () => {
    window.open("https://telegram.me/+6Fg21XqjS0swYjE1", "_blank");
  };

  return (
    <div className="App" onClick={isMobileDevice() ? openTelegram : null}>
      <div class="visual">
        <img class="avatar" src="baba.jpg" alt="Baba" />
      </div>
      <div className="name">
        BABA<div>THE BEST TIPPER</div>
      </div>
      <div>
        <button class="btn">
          <span>
            <i
              class="fa fa-hand-o-right"
              style={{ fontSize: "1.6rem", color: "red" }}
            ></i>
            &nbsp;JOIN TELEGRAM CHANNEL&nbsp;
            <i
              class="fa fa-hand-o-left"
              style={{ fontSize: "1.6rem", color: "red" }}
            ></i>
          </span>
        </button>
      </div>
      <p>🔰 𝗜𝗣𝗟 𝟮𝟬𝟮𝟯 - 𝟳𝟮/𝟳𝟰 𝗣𝗔𝗦𝗦 🎖️</p>
      <p>🔰 𝗔𝗦𝗜𝗔 𝗖𝗨𝗣 - 𝟭𝟮/𝟭𝟮 𝗣𝗔𝗦𝗦 🎖️</p>
      <div style={{ height: "1rem" }} />
      <p>🔰 𝗜𝗣𝗟 - 𝗣𝗦𝗟 - 𝗕𝗕𝗟 𝗙𝗜𝗫𝗫 𝗔𝗩𝗔𝗜𝗟𝗔𝗕𝗟𝗘 🎖️</p>
      <p>🔰 𝗞𝗜𝗡𝗚 𝗢𝗙 𝗜𝗣𝗟 𝗥𝗘𝗣𝗢𝗥𝗧𝗦 🎖️</p>
      <p>🔰 𝗙𝗜𝗫 𝗧𝗢𝗦𝗦 - 𝗠𝗔𝗧𝗖𝗛 - 𝗦𝗘𝗦𝗦𝗜𝗢𝗡🎖️</p>

      <div className="item button-pulse">
        <button className="button">
          <img src="logo.webp" alt="Logo" />
          <div className="join">&nbsp;JOIN TELEGRAM</div>
        </button>
      </div>
      <div className="accurate">👆 100% FIXED IPL REPORTS 👆</div>
    </div>
  );
}

export default App;
